import { getFormProps, getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { json, type ActionFunctionArgs, type MetaFunction } from '@remix-run/cloudflare';
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react';
import bcrypt from 'bcryptjs';
import { z } from 'zod';
import { analyticsService } from '~/backend/services/analyticsService';
import { ErrorList, Field } from '~/my-components/forms';
import { StatusButton } from '~/my-components/status-button';
import { EmailSchema, PasswordSchema, redirectToKey } from '~/types';
import { createUserSessionAndRedirect, getRedirectToFromRequest } from '~/utils/auth.server';
import { getPrisma } from '~/utils/db.server';
import { useIsPending } from '~/utils/misc';
import { routes } from '~/utils/routes';

const Schema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
});

export async function action({ request, context }: ActionFunctionArgs) {
  const formData = await request.formData();
  const prisma = getPrisma({ context });
  const redirectTo = getRedirectToFromRequest(request);

  const submission = await parseWithZod(formData, {
    schema: Schema.transform(async (data, ctx) => {
      const existingUser = await prisma.user.findUnique({
        where: { email: data.email },
        select: { email: true, password: true, id: true },
      });
      if (existingUser) {
        const isValidPassword = await bcrypt.compare(data.password, existingUser.password);
        if (isValidPassword) {
          return existingUser;
        }
      }
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.custom,
        message: 'Incorrect email or password. Please try again.',
      });
      return;
    }),
    async: true,
  });
  if (submission.status !== 'success' || !submission.value?.id) {
    return json({ result: submission.reply() }, { status: submission.status === 'error' ? 400 : 200 });
  }

  analyticsService.sendEvent({ type: 'login' });
  return createUserSessionAndRedirect(submission.value.id, context, redirectTo);
}

export const meta: MetaFunction = () => {
  return [{ title: 'Log In | Deliberate Time' }];
};

export default function Component() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(redirectToKey);

  const [form, fields] = useForm({
    id: 'form',
    constraint: getZodConstraint(Schema),
    lastResult: actionData?.result,
    onValidate({ formData }) {
      const result = parseWithZod(formData, { schema: Schema });
      return result;
    },
    shouldRevalidate: 'onBlur',
  });

  return (
    <div className="container flex flex-col justify-center pb-32 pt-20">
      <div className="text-center">
        <h1 className="text-h1">Log in.</h1>
      </div>
      <div className="mx-auto mt-8 min-w-full max-w-sm sm:min-w-[368px]">
        <Form method="POST" {...getFormProps(form)}>
          <Field
            labelProps={{
              htmlFor: fields.email.id,
              children: 'Email',
            }}
            inputProps={{
              ...getInputProps(fields.email, { type: 'email' }),
              autoFocus: true,
              autoComplete: 'email',
            }}
            errors={fields.email.errors}
          />
          <Field
            labelProps={{
              htmlFor: fields.password.id,
              children: 'Password',
            }}
            inputProps={{
              ...getInputProps(fields.password, { type: 'password' }),
              autoComplete: 'new-password',
            }}
            errors={fields.password.errors}
          />
          <ErrorList errors={form.errors} id={form.errorId} />
          <StatusButton
            className="w-full"
            status={isPending ? 'pending' : form.status ?? 'idle'}
            type="submit"
            disabled={isPending}
          >
            Submit
          </StatusButton>
        </Form>
        <p className="mt-4 text-center text-sm">
          Don't have an account?{' '}
          <Link to={`${routes.register}?redirectTo=${encodeURIComponent(redirectTo ?? '/')}`} className="underline">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
}
